const CoreServicesShowcaseData = [
  {
    title: 'Custom Software Development',
    features: [
      'Web & mobile app solutions tailored to your business needs',
      'Agile development process ensuring quick iterations and adaptability',
      'Prototyping & MVP development for early market validation',
      'Scalable architecture to grow with your business',
      'Seamless integration with third-party APIs and existing systems',
      'Enhanced security protocols and data protection measures',
      'User-centric design ensuring optimal user experience',
      'Cloud-based or on-premise deployment options',
      'Cross-platform support for mobile, desktop, and web applications',
      'Full documentation and training provided for internal teams',
      'Launch and post-launch support including updates and maintenance',
    ],
    ctaText: 'Learn More',
    featured: false,
  },
  {
    title: 'Process Automation (RPA)',
    features: [
      'Automate repetitive tasks across multiple departments',
      'Increase efficiency & reduce human error through automated workflows',
      'Leverage cutting-edge RPA tools such as UiPath, Blue Prism, and Automation Anywhere',
      'Full deployment & support, from pilot testing to organisation-wide rollout',
      'Reduce operational costs and improve task accuracy',
      'Integration with legacy systems and modern cloud platforms',
      'Scalable automation solutions tailored to business growth',
      'Comprehensive process mapping to identify automation opportunities',
      'Compliance and security checks for every automated process',
      'Ongoing monitoring and performance optimisation for automated workflows',
      'Staff training and knowledge transfer for long-term success',
    ],
    ctaText: 'Get Started',
    featured: true,
  },
  {
    title: 'Technology Strategy Consulting',
    features: [
      'Comprehensive tech audits covering hardware, software, and network infrastructure',
      'In-depth stakeholder interviews & analysis to align technology with business goals',
      'Long-term technology roadmaps to future-proof your business',
      'Consultative support & implementation guidance at every step',
      'Recommendations on cost-saving measures and technology optimisation',
      'Cloud strategy development for migration, management, and scaling',
      'Vendor management and negotiation to secure the best technology deals',
      'Evaluation of emerging technologies (AI, Blockchain, IoT) for business advantage',
      'Risk assessment and mitigation strategies for technology implementation',
      'Cybersecurity assessment and improvement recommendations',
      'Support with regulatory compliance for industry-specific technology standards',
    ],
    ctaText: 'Contact Us',
    featured: false,
  },
];

export default CoreServicesShowcaseData;
