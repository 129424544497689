import React from 'react';
import {Button} from '@material-ui/core';
import {Visibility} from '@material-ui/icons';
import ProjectList from './ProjectList';
import '../css/project.css';
function Projects() {
  return (
    <div className="projects" id="projects">
      <div className="projects__container">
        <ProjectList />
      </div>
    </div>
  );
}

export default Projects;
