import React from 'react';
import {motion} from 'framer-motion';
import Banner from '../images/about-banner.png';
import '../css/About.css';
import OurStory from '../about-component/OurStory';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="about-banner">
          <img src={Banner} alt="" />
          <div className="overlay">
            <h1 className="title">About Us</h1>
          </div>
        </div>
        <div className="story">
          <OurStory />
        </div>
      </div>
      <div className="call-to-action-btn">
        <h1>Join Forces with Us, Let's Make an Impact Together!</h1>
        <Button>
          <Link to="/contact-us">Partner with Us</Link>
        </Button>
      </div>
    </div>
  );
}

export default About;
