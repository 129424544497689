// Error.js
import React, {useEffect} from 'react';

const Error = ({message, clearError}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      clearError();
    }, 10000);
    // window.scrollTo({top: 0, behavior: 'smooth'});
    return () => clearTimeout(timeout);
  }, [message, clearError]);

  return <div className="error">{message}</div>;
};

export default Error;
