import React, {useState, useEffect, useRef} from 'react';
import {Button} from '@material-ui/core';
import {NavigateNext, NavigateBefore} from '@material-ui/icons';
import serviceListJson from '../pages/ServiceListJson.json';
import consultingLandingImg from '../images/consulting-landing-page.png';
import customSoftwareImg from '../images/custom-software-landing-page.png';
import apiDevImg from '../images/api-dev-landing-page.png';
import automationImg from '../images/automation-landing-page.png';
import uxUIImg from '../images/ux-ui-landing-page.png';
import cloudImg from '../images/cloud-landing-page.png';
import supportImg from '../images/support-landing-page.png';
import '../css/services.css';
function Services() {
  const [serviceData, setServiceData] = useState([]);
  const refElement = useRef(null);

  useEffect(() => {
    const importedImage = [
      consultingLandingImg,
      customSoftwareImg,
      apiDevImg,
      automationImg,
      uxUIImg,
      cloudImg,
      supportImg,
    ];
    for (let i = 0; i < serviceListJson.length; i++) {
      serviceListJson[i].image = importedImage[i];
    }
  }, []);

  useEffect(() => {
    setServiceData(serviceListJson);
  }, []);

  const scrollRightHandler = (moveLeft) => {
    refElement.current.scrollLeft += moveLeft;
  };
  const scrollLeftHandler = (moveLeft) => {
    refElement.current.scrollLeft += moveLeft;
  };
  return (
    <div className="services" id="services">
      <div className="services__container" ref={refElement}>
        <div className="services__body">
          {serviceData.map((data) => (
            <div key={data.bodyTitle} className="content">
              <div className="content__body">
                <img src={data.image} alt="" />
                <h3> {data.bodyTitle} </h3>
                <p> {data.bodyContent} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="services__btn service__list__btn">
        <div onClick={() => scrollLeftHandler(-280)} className="btn">
          <NavigateBefore />
          <Button className="prev">prev</Button>
        </div>
        <div onClick={() => scrollRightHandler(280)} className="btn">
          <Button id="test" className="next">
            next
          </Button>
          <NavigateNext />
        </div>
      </div>
    </div>
  );
}

export default Services;
