import React from 'react';
import {Star} from '@material-ui/icons';
import ShowCase from './pages/Showcase';
import ServiceList from './pages/ServiceList';
import Process from './pages/Process';
import Projects from './pages/Projects';
import FeedBack from './pages/FeedBack';

import './css/landing.css';
import CoreServicesShowcase from './landing-components/CoreServicesShowcase';
function Landing() {
  return (
    <div className="landing" id="landing">
      <ShowCase />
      <div className="landing__services__wrapper">
        <div className="title__landing">
          <h2>Our services</h2>
        </div>
        <ServiceList />
      </div>

      {/* <div className="landing__services__include__wrapper">
        <div className="title__landing">
          <h2>Our services include</h2>
        </div>
        <Services />
      </div> */}

      <div className="landing__projects__wrapper">
        <div className="title__landing">
          <h2>Our Clients </h2>
        </div>
        <Projects />
      </div>

      <div className="landing__pricing__wrapper">
        <div className="title__landing">
          <h2>TechnoEFX Core Services: Driving Innovation and Efficiency</h2>
        </div>
        {/* <Pricing /> */}
        <CoreServicesShowcase />
      </div>

      <div className="landing__process__wrapper">
        <Process />
      </div>

      <div className="wrap">
        <div className="wrap__container landing__feedback">
          <div className="title__landing">
            <h2>Testimonials</h2>
            <div className="icons__div">
              <Star className="icon" />
              <Star className="icon" />
              <Star className="icon" />
              <Star className="icon" />
              <Star className="icon" />
            </div>
          </div>
          <FeedBack />
        </div>
      </div>
    </div>
  );
}

export default Landing;
