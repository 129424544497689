import React from 'react';
import '../css/projectList.css';
import unKnown from '../images/unknown.png';
import walmartImg from '../images/walmart.png';
import superdryImg from '../images/superdry.png';
import cbCollegeImg from '../images/cbcollege.png';
import daemonImg from '../images/daemon.png';
import unityhorizonsImg from '../images/unityhorizons.png';
import hormudImg from '../images/hormud.png';
const ProjectList = ({images, speed = 5000}) => {
  const projects = [
    {
      imgSrc: walmartImg,
    },
    {
      imgSrc: hormudImg,
    },
    {
      imgSrc: cbCollegeImg,
    },
    {
      imgSrc: unityhorizonsImg,
    },
    {
      imgSrc: superdryImg,
    },
    {
      imgSrc: daemonImg,
    },
    {
      imgSrc: walmartImg,
    },
    {
      imgSrc: hormudImg,
    },
    {
      imgSrc: cbCollegeImg,
    },
    {
      imgSrc: unityhorizonsImg,
    },
    {
      imgSrc: superdryImg,
    },
    {
      imgSrc: daemonImg,
    },
    {
      imgSrc: walmartImg,
    },
    {
      imgSrc: hormudImg,
    },
    {
      imgSrc: cbCollegeImg,
    },
    {
      imgSrc: unityhorizonsImg,
    },
    {
      imgSrc: superdryImg,
    },
    {
      imgSrc: daemonImg,
    },
  ];

  return (
    <div className="project-list">
      <div className="inner">
        <div className="wrapper" style={{'--speed': `${speed}ms`}}>
          {projects.map((projects, index) => (
            <div className="project-card" key={index}>
              <img src={projects.imgSrc} alt="img" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
