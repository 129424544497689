import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import '../css/BlogPost.css';
import {Button} from '@material-ui/core';
import {motion} from 'framer-motion';
const BlogPost = ({key, post}) => {
  const ref = useRef(null);

  return (
    <motion.div
      ref={ref}
      className="blog-post"
      initial={{opacity: 0, x: 'auto'}}
      whileInView={{opacity: 1, x: 0}}
      transition={{
        delay: 0.05,
        duration: 2,
        type: 'tween',
        stiffness: 120,
      }}
    >
      <div className="post-img">
        <Link to={`/blog/${post.id}`} className="read-more">
          <img src={post.imageUrl} alt={post.title} />
        </Link>{' '}
      </div>
      <div className="post-details">
        <h2>{post.title}</h2>
        <p className="author">
          {post.author} - {post.date}
        </p>
        <p>{post.introContent}</p>
      </div>
      <div className="post-btn">
        <Link to={`/blog/${post.id}`} className="read-more">
          <Button className="read-more-btn">Read More</Button>
        </Link>{' '}
      </div>
    </motion.div>
  );
};

export default BlogPost;
