import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import blogPosts from './blogPostsData.json';
import '../css/BlogPostPage.css';
import {ArrowBackRounded} from '@material-ui/icons';

const BlogPostPage = () => {
  const {postId} = useParams();
  const navigate = useNavigate();
  const postRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    // Save scroll position when component mounts
    setScrollPosition(postRef.current.scrollTop);
  }, []);

  const post = blogPosts.find((post) => post.id === parseInt(postId));

  if (!post) {
    return <div>Post not found</div>;
  }

  // Split long content into paragraphs
  const paragraphs = post.longContent
    .split('\n')
    .map((paragraph, index) => <p key={index}>{paragraph}</p>);

  const handleGoBack = () => {
    // Go back to the previous page and restore scroll position
    navigate(-1, {state: {scrollPosition}});
  };

  return (
    <div className="blog-post-content" ref={postRef}>
      <div className="go-back-link">
        <button onClick={handleGoBack} className="back-link">
          <ArrowBackRounded />
          <h3>back to blog</h3>
        </button>
      </div>
      <div className="container">
        <img src={post.imageUrl} alt={post.title} />
        <div className="post-details">
          <div className="blog-details-header">
            <h2>{post.title}</h2>
            <p className="author">
              {post.author} - {post.date}
            </p>
          </div>
          <div className="blog-details-content">
            <p>{post.introContent}</p>
            {paragraphs}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostPage;
