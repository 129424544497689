import React from 'react';
import '../css/CookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <div className="cookie-policy-container">
        <h1>Cookie Policy</h1>
        <p>
          Welcome to TechnoEFX’s ("TechnoEFX", "we", "us", "our") Cookie Policy.
          This policy explains how we use cookies and similar technologies on
          our website. By continuing to use our website, you agree to our use of
          cookies as described in this policy.
        </p>

        <h2>1. What Are Cookies?</h2>
        <p>
          Cookies are small text files stored on your device when you visit a
          website. They help websites to function effectively and provide
          insights to improve user experiences.
        </p>

        <h2>2. How We Use Cookies</h2>
        <p>
          At TechnoEFX, we use cookies to enhance your browsing experience,
          analyse how our site is used, and provide relevant marketing content.
          The data collected helps us ensure the best possible experience and
          personalised services.
        </p>
        <ul>
          <li>
            <strong>Essential Cookies:</strong> These are necessary for the
            basic functionality of our website, such as navigating pages and
            accessing secure areas.
          </li>
          <li>
            <strong>Performance Cookies:</strong> These help us understand how
            visitors use our site and improve its performance. All data
            collected by these cookies is aggregated and anonymous.
          </li>
          <li>
            <strong>Functionality Cookies:</strong> These enable us to remember
            your preferences, allowing us to personalise your experience, such
            as saving your language or region preferences.
          </li>
          <li>
            <strong>Targeting Cookies:</strong> These cookies track your
            browsing habits, helping us to deliver adverts and content that are
            more relevant to you. We may share this information with third
            parties, such as advertisers.
          </li>
        </ul>

        <h2>3. Third-Party Cookies</h2>
        <p>
          Third parties, including advertising networks and web traffic analysis
          services like Google Analytics, may use cookies on our website. These
          third-party cookies help us understand how our website is being used
          and optimise our marketing strategies.
        </p>

        <h2>4. Managing Cookies</h2>
        <p>
          You can manage or disable cookies in your browser settings at any
          time. However, disabling cookies may affect your ability to use
          certain features of our website. You can also control your cookie
          preferences using our cookie consent banner that appears when you
          first visit our website.
        </p>

        <h2>5. Updates to This Cookie Policy</h2>
        <p>
          We may update this policy from time to time to reflect changes in our
          use of cookies. Please check this page periodically to ensure you are
          aware of any updates.
        </p>

        <h2>6. Contact Us</h2>
        <div className="address__section">
          <p>
            If you have any questions about our use of cookies, please contact
            us:
          </p>
          <p>
            TechnoEFX
            <br />
            Email: privacy@technoefx.co.uk
            <br />
            Phone: +44 (0) 7475 566 762
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
