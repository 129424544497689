import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// import Countdown from 'react-countdown';
import Landing from '../components/Landing';
import PrivacyPolicy from '../components/pages/PrivacyPolicy';
import Nav from '../components/nav-component/Nav';
import Footer from '../components/pages/Footer';
import '../components/css/App.css';
import NotFound from '../components/NotFound';
import Blog from '../components/blog-components/Blog';
import BlogPostPage from '../components/blog-components/BlogPostPage';
import ScrollToTop from '../components/common/ScrollToTop';
import CoreServices from '../components/services-component/CoreServices';
import About from '../components/pages/About';
import ContactUsPage from '../components/pages/ContactUsPage';
import CookiePolicy from '../components/pages/CookiePolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Nav />
        <div className="content__wrap">
          <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/blog/page/:pageNumber" element={<Blog />} />
            <Route path="/blog/:postId" element={<BlogPostPage />} />
            {/* <Route path="/services/:serviceId" element={<CoreServices />} /> */}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
