import React, {useRef} from 'react';
import {motion} from 'framer-motion';
import '../css/OurStory.css';
import {ourStoryFadeInAnimationVariant} from '../common/AnimationVariants';

const OurStory = () => {
  const ref = useRef(null);

  return (
    <div className="our__story">
      <div className="container">
        <motion.h2
          ref={ref}
          className="headline__title"
          variants={ourStoryFadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
          viewport={{once: true}}
          style={{color: '#05E5C8'}}
        >
          TechnoEFX: Empowering Digital Innovation
        </motion.h2>
        <div className="content-wrapper">
          <motion.div
            className="content"
            initial={{opacity: 0, x: 'auto'}}
            whileInView={{opacity: 1, x: 0}}
            transition={{
              delay: 0.05,
              duration: 2,
              type: 'tween',
              stiffness: 120,
            }}
            viewport={{once: true}}
          >
            <h3 className="title">WHO ARE WE?</h3>
            <p>
              At <strong>TechnoEFX</strong>, we are driven by a single purpose:
              to empower businesses through innovative technology solutions. Our
              story began with a passion for solving complex challenges and
              delivering custom, scalable digital products that fuel growth.
            </p>
            <p>
              Founded by a team of experienced developers, strategists, and
              designers, TechnoEFX is more than just a software consultancy. We
              are problem solvers and innovators who believe that technology
              should adapt to your business needs, not the other way around. Our
              goal is to create long-lasting partnerships where we work together
              to build impactful solutions.
            </p>
          </motion.div>
          <motion.div
            className="content"
            initial={{opacity: 0, x: 'auto'}}
            whileInView={{opacity: 1, x: 0}}
            transition={{
              delay: 0.09,
              duration: 2,
              type: 'tween',
              stiffness: 120,
            }}
            viewport={{once: true}}
          >
            <h3 className="title">WHAT WE DO!</h3>
            <div className="list">
              <p>
                At <strong>TechnoEFX</strong>, we offer end-to-end technology
                solutions ranging from custom software development and process
                automation (RPA) to strategic consulting. Our expertise spans
                across multiple sectors, helping businesses embrace digital
                transformation, improve operational efficiency, and unlock new
                growth opportunities.
              </p>
              <p>
                Our approach revolves around collaboration. We don’t just build
                software – we work closely with our clients to understand their
                challenges, define their needs, and deliver tailored solutions
                that meet their long-term goals. With cutting-edge technology,
                industry expertise, and an agile mindset, we bring ideas to
                life, driving your business forward in a rapidly evolving
                digital landscape.
              </p>
              <p>
                <strong>Looking Ahead:</strong> At TechnoEFX, we are committed
                to continuously pushing the boundaries of what’s possible with
                technology. Our team stays ahead of the curve, embracing the
                latest advancements to ensure that our clients remain
                competitive in today’s digital world. Let’s work together to
                transform your vision into reality.
              </p>
              <p>
                <strong>
                  Join us in shaping the future of your business with smart
                  technology, impactful innovation, and sustainable growth.
                </strong>
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
