import React, {useState, useEffect} from 'react';
import {Button} from '@material-ui/core';
import {motion, AnimatePresence} from 'framer-motion';
import image from '../images/showcase-banner-img.png';
import image2 from '../images/showcase-banner-img3.png';

import '../css/showcase.css';
import ContactModal from './ContactModal';

function Showcase() {
  const [openForm, setOpenForm] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [image, image2];

  // Function to cycle through images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  const CloseFormHandler = () => {
    setOpenForm(false);
  };

  const variants = {
    enter: {opacity: 0, x: 100}, // Starting state of the new image
    center: {opacity: 1, x: 0}, // Visible state
    exit: {opacity: 0, x: -100}, // Exit state of the old image
  };

  return (
    <div className="showCase" id="showcase">
      <div
        onClick={() => setOpenForm(false)}
        className={`${openForm && 'overlay'}`}
      ></div>
      <div className="showCase__container">
        <ContactModal
          openFormProp={openForm}
          closeFormProp={CloseFormHandler}
        />
        <div className="wrapper">
          <div className="title">
            <p className="question__para">
              Smart Technology <span className="divider">|</span> Real Results{' '}
              <span className="divider">|</span> Sustainable Growth{' '}
            </p>
            <p>
              At <strong>TechnoEFX</strong>, we help businesses grow through
              intelligent software solutions and process automation. Our
              tailored approach ensures your technology is designed to scale,
              optimise operations, and drive long-term success in an
              ever-evolving digital landscape.
            </p>
            <div className="btn">
              <Button onClick={() => setOpenForm(true)} className="btn__icon">
                Get a Free Consultation
              </Button>
            </div>
          </div>
        </div>

        <div className="showCase__img">
          <AnimatePresence>
            <motion.img
              key={currentImageIndex} // Ensure each image has a unique key for AnimatePresence
              src={images[currentImageIndex]}
              alt={`img-${currentImageIndex}`}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{duration: 1}}
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default Showcase;
