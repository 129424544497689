import React from 'react';
import '../css/privacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>
          Welcome to TechnoEFX’s ('TechnoEFX', 'us', 'we', and 'our') privacy
          notice.
        </p>

        <p>
          TechnoEFX respects your privacy and is committed to protecting your
          personal data. This privacy notice will inform you about how we
          collect, use, and protect your personal data when you visit our
          website or engage us for software development services. It will also
          explain your privacy rights and how the law protects you.
        </p>

        <h2>1. Important Information and Who We Are</h2>
        <h3>Purpose of this Privacy Notice</h3>
        <p>
          This privacy notice aims to provide you with information on how
          TechnoEFX collects and processes your personal data through your use
          of this website, including any data you may provide when filling out
          forms, such as the contact form, or when engaging with our software
          services.
        </p>

        <p>
          This website is not intended for children, and we do not knowingly
          collect data related to children.
        </p>

        <p>
          It is important that you read this privacy notice together with any
          other privacy or fair processing notices we may provide on specific
          occasions when collecting or processing personal data. This privacy
          notice supplements other notices and is not intended to override them.
        </p>

        <h3>Controller</h3>
        <p>
          TechnoEFX is the controller and responsible for your personal data
          (referred to as 'TechnoEFX', 'we', 'us', or 'our' in this privacy
          notice). TechnoEFX may also act as a data processor for personal data
          we process on behalf of our clients as part of our software services,
          under the terms of our agreements with clients.
        </p>

        <p>
          We have appointed a Data Protection Officer (DPO) who is responsible
          for overseeing questions in relation to this privacy notice. If you
          have any questions, including any requests to exercise your legal
          rights, please contact our DPO using the details below.
        </p>

        <h3>Contact Details</h3>
        <p>TechnoEFX</p>
        <p>Data Protection Officer</p>
        <p>Email: privacy@technoefx.com</p>
        <p>
          Address: 960 Capability Green, TechnoEFX, Luton, Bedfordshire, LU1 3PE
        </p>
        <p>Phone: +44 (0) 7475 566 762</p>

        <p>
          You have the right to make a complaint at any time to your local data
          protection authority. However, we would appreciate the chance to
          resolve your concerns first, so please contact us.
        </p>

        <h3>
          Changes to the Privacy Notice and Your Duty to Inform Us of Changes
        </h3>
        <p>
          We keep our privacy notice under regular review. This version was last
          updated in September 2024.
        </p>

        <p>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us.
        </p>

        <h3>Third-Party Links</h3>
        <p>
          Our website may include links to third-party websites, plug-ins, and
          applications. Clicking on those links may allow third parties to
          collect or share data about you. We do not control these third-party
          websites and are not responsible for their privacy statements. We
          encourage you to read the privacy notice of every website you visit.
        </p>

        <h2>2. The Data We Collect About You</h2>
        <p>
          Personal data means any information about an individual from which
          that person can be identified. It does not include data where the
          identity has been removed (anonymous data).
        </p>

        <p>
          We may collect, use, store, and transfer different kinds of personal
          data about you, including:
        </p>
        <ul>
          <li>
            <strong>Identity Data</strong> (e.g., first name, last name, title,
            job title)
          </li>
          <li>
            <strong>Contact Data</strong> (e.g., billing address, email, phone
            number)
          </li>
          <li>
            <strong>Technical Data</strong> (e.g., IP address, browser type,
            time zone setting, location)
          </li>
          <li>
            <strong>Profile Data</strong> (e.g., username, preferences,
            feedback)
          </li>
          <li>
            <strong>Usage Data</strong> (e.g., information about how you use our
            website or services)
          </li>
          <li>
            <strong>Marketing and Communications Data</strong> (e.g.,
            preferences in receiving marketing from us)
          </li>
        </ul>

        <p>
          We do not collect any Special Categories of Personal Data (e.g., race,
          religion, health data).
        </p>

        <h2>3. How We Collect Your Personal Data</h2>
        <p>
          We use different methods to collect data from and about you,
          including:
        </p>
        <ul>
          <li>
            <strong>Direct Interactions</strong>: You may provide personal data
            by filling in forms or corresponding with us via phone, email, or
            otherwise.
          </li>
          <li>
            <strong>Automated Technologies</strong>: We collect Technical Data
            as you interact with our website using cookies and other similar
            technologies.
          </li>
          <li>
            <strong>Third Parties</strong>: We may receive personal data from
            analytics providers (e.g., Google Analytics) or publicly available
            sources.
          </li>
        </ul>

        <h2>4. How We Use Your Personal Data</h2>
        <p>
          We will only use your personal data when the law allows us to. We
          commonly use your data to:
        </p>
        <ul>
          <li>
            Provide software development services based on your requests or
            contracts
          </li>
          <li>Manage payments, invoices, and customer support</li>
          <li>
            Analyze how you use our services for improvement and development
          </li>
          <li>Send you marketing communications based on your preferences</li>
        </ul>

        <h2>5. Disclosures of Your Personal Data</h2>
        <p>We may share your personal data with:</p>
        <ul>
          <li>
            Service providers who provide IT and system administration services
          </li>
          <li>Third parties for marketing purposes (only with your consent)</li>
          <li>Professional advisers (e.g., lawyers, auditors) as needed</li>
          <li>Government authorities if required by law</li>
        </ul>

        <h2>6. Data Security</h2>
        <p>
          We have put in place security measures to prevent unauthorized access
          to your personal data. We limit access to those employees, agents,
          contractors, and third parties who have a business need to know and
          will only process your data according to our instructions.
        </p>

        <h2>7. Data Retention</h2>
        <p>
          We will retain your personal data only for as long as necessary to
          fulfill the purposes for which we collected it, including legal or
          regulatory requirements.
        </p>

        <h2>8. Your Legal Rights</h2>
        <p>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data, including:
        </p>
        <ul>
          <li>Requesting access to your data</li>
          <li>Requesting correction of your data</li>
          <li>Requesting deletion of your data</li>
          <li>Objecting to processing of your data</li>
          <li>Requesting restriction of processing</li>
          <li>Requesting transfer of your data</li>
          <li>Withdrawing consent to data processing</li>
        </ul>

        <p>If you wish to exercise any of these rights, please contact us.</p>

        <p>
          Thank you for trusting TechnoEFX. Your privacy is important to us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
