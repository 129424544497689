import React, {useState} from 'react';
import {Button} from '@material-ui/core';
import {Check} from '@material-ui/icons';
import {FaTools, FaCogs, FaLightbulb} from 'react-icons/fa'; // Importing icons from react-icons

import ContactModal from '../pages/ContactModal';
import servicesData from './CoreServicesShowcaseData'; // Assuming this contains your service details

import '../css/CoreServicesShowcase.css';

function CoreServicesShowcase() {
  const [openForm, setOpenForm] = useState(false);
  const [currentService, setCurrentService] = useState('');

  const openFormHandler = (e) => {
    e.preventDefault();
    setCurrentService(
      e.currentTarget.parentElement.firstChild.firstChild.innerHTML
    );
    setOpenForm(true);
  };

  const CloseFormHandler = () => {
    setOpenForm(false);
  };

  return (
    <div className="services__showcase" id="services__showcase">
      <div
        onClick={() => setOpenForm(false)}
        className={`${openForm && 'overlay'}`}
      ></div>
      <div className="intro__services">
        <p>
          At TechnoEFX, we deliver tailored, cutting-edge solutions that address
          your unique business challenges. Our top three services are designed
          to enhance operational efficiency, foster innovation, and support
          sustainable growth. Explore our expertise below and discover how we
          can transform your technology landscape.
        </p>
      </div>

      <div className="services__container">
        <ContactModal
          currentService={currentService}
          openFormProp={openForm}
          closeFormProp={CloseFormHandler}
        />

        {servicesData.map((service, index) => (
          <div
            key={index}
            className={`content ${service.featured ? 'full__package' : ''}`}
          >
            <div className="title">
              <h2>{service.title}</h2>
            </div>
            <div className="body">
              <ul>
                {service.features.map((feature, i) => (
                  <div className="ul__div" key={i}>
                    <Check /> <li>{feature}</li>
                  </div>
                ))}
              </ul>
            </div>
            <div onClick={openFormHandler} className="btn">
              <Button>{service.ctaText}</Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CoreServicesShowcase;
