import React, {useState} from 'react';
import {Button} from '@material-ui/core';
import logo from '../images/main-logo.png';
// import {Link as PageLink} from 'react-router-dom'
import {Link as ScrollLink} from 'react-scroll';
import {Link} from 'react-router-dom';
import emailjs from 'emailjs-com';

// import axios from '../axios';
import {
  SettingsApplications,
  LocalOffer,
  LocationOn,
  Phone,
  LibraryBooks,
  Security,
  People,
  Call,
} from '@material-ui/icons';
import {LinkedIn, Email, Instagram, Twitter} from '@material-ui/icons';
import '../css/footer.css';
import Error from '../common/Error';
import Success from '../common/Success';
import {FaCookieBite} from 'react-icons/fa';
function Footer() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);
  const [successMsg, setSucessMsg] = useState('');
  const [success, setSuccess] = useState('');

  const formSubmitHandler = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_NEWSLETTER_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_YOUR_USER_ID
      )
      .then((res) => {
        setName('');
        setEmail('');
        setSucessMsg(
          'Thanks for subscribing to our email list! We will keep you up to date.'
        );
        setSuccess(true);
        setTimeout(() => {
          setSucessMsg('');
          setSuccess(false);
        }, 7000);
      })
      .catch((err) => {
        setErrorMsg(
          'There was an error submitting the form. Please try again.'
        );
        setError(true);
        setTimeout(() => {
          setErrorMsg('');
          setError(false);
        }, 5000);
      });
  };

  return (
    <div className="footer">
      <div className="news__letter">
        {error && <Error message={errorMsg} clearError={() => setError('')} />}{' '}
        {/* Display error message if error state is set */}
        {success && (
          <Success message={successMsg} clearSuccess={() => setSuccess('')} />
        )}{' '}
        <div className="news__letter__container">
          <div className="title">
            <h2>
              Sign up to our <span>Newsletter</span>
            </h2>
            <p>Subscribe to receive our latest updates and offers.</p>
            {/* <p>In subscribing your agree to these terms</p> */}
          </div>
          <form onSubmit={formSubmitHandler}>
            <div className="input__div">
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                name="name"
                required
                placeholder="Your Full Name"
              />
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                name="email"
                required
                placeholder="Your Email"
              />
            </div>
            <Button type="submit">Sign up</Button>
          </form>
        </div>
      </div>
      <div className="footer__container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="wrap__div">
          <div className="links">
            <ul>
              {window.location.pathname === '/' ? (
                <>
                  <div className="ul__div ">
                    {' '}
                    <People className="ul__icon" />{' '}
                    <Link to="/about">
                      <li>About </li>
                    </Link>{' '}
                  </div>

                  <div className="ul__div ">
                    {' '}
                    <Call className="ul__icon" />{' '}
                    <Link to="/contact-us">
                      <li>contact Us </li>
                    </Link>{' '}
                  </div>
                  <div className="ul__div ">
                    {' '}
                    <LibraryBooks className="ul__icon" />{' '}
                    <Link to="/blog">
                      <li>Blog </li>
                    </Link>{' '}
                  </div>
                  <div className="ul__div ">
                    {' '}
                    <Security className="ul__icon" />
                    <Link to="/privacy-policy">
                      <li>Privacy Policy </li>
                    </Link>
                  </div>
                  <div className="ul__div ">
                    {' '}
                    <FaCookieBite className="ul__icon" />
                    <Link to="/cookie-policy">
                      <li>Cookie Policy </li>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="ul__div ">
                    {' '}
                    <People className="ul__icon" />{' '}
                    <Link to="/about">
                      <li>About </li>
                    </Link>{' '}
                  </div>

                  <div className="ul__div ">
                    {' '}
                    <Call className="ul__icon" />{' '}
                    <Link to="/contact-us">
                      <li>contact Us </li>
                    </Link>{' '}
                  </div>
                  <div className="ul__div ">
                    {' '}
                    <LibraryBooks className="ul__icon" />{' '}
                    <Link to="/blog">
                      <li>Blog </li>
                    </Link>{' '}
                  </div>
                  <div className="ul__div ">
                    {' '}
                    <Security className="ul__icon" />
                    <Link to="/privacy-policy">
                      <li>Privacy Policy </li>
                    </Link>
                  </div>
                  <div className="ul__div ">
                    {' '}
                    <FaCookieBite className="ul__icon" />
                    <Link to="/cookie-policy">
                      <li>Cookie Policy </li>
                    </Link>
                  </div>
                </>
              )}
            </ul>
          </div>
          <div className="contact__info">
            <div className="contact__info__div">
              <LocationOn className="icons" />
              <p>United Kindom, Luton</p>{' '}
            </div>
            <div className="contact__info__div">
              <Email className="icons" />{' '}
              <p>
                <a href="mailto:info@bkaydesigns.com" rel="noreferrer ">
                  info@technoefx.com
                </a>
              </p>
            </div>
            <div className="contact__info__div">
              <Phone className="icons" />
              <p>
                {' '}
                <a href="tel:+447709784016">+44 (0) 7475 566 762</a>
              </p>{' '}
            </div>
          </div>
          <div className="socials">
            <h3>Follow us</h3>
            <div className="icons">
              <div>
                {' '}
                <a
                  href="https://www.linkedin.com/in/khalid-ibrahim-3b2b71201/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn className="icon lk" />
                </a>{' '}
              </div>
              <div>
                {' '}
                <a
                  href="https://www.instagram.com/technoefx_/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram className="icon ig" />
                </a>{' '}
              </div>
              {/* <div>
                {' '}
                <a
                  href="https://twitter.com/bkdesignplus"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter className="icon fb" />{' '}
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
            Copyright ©2024 <a href="https://technoefx.com/">technoefx</a>{' '}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
