import React, {useState} from 'react';
import axios from '../../containers/axios';
import '../css/SubscribeToBlog.css';
import Error from '../common/Error'; // Import Error component
import Success from '../common/Success'; // Import Success component

const SubscribeToBlog = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/subscribe-to-blog', {
        email: email,
      });
      setSuccess(response.data.message); // Handle success
      setError(null); // Reset error state
      setEmail('');
    } catch (error) {
      setError(error.response.data.message); // Set error message
      setSuccess(null); // Reset success state
    }
  };

  return (
    <div id="subscribe-to-blog" className="subscribe-to-blog">
      <h2>Subscribe to Our Blog</h2>
      <p>Stay updated with our latest posts</p>
      {error && <Error message={error} clearError={() => setError('')} />}{' '}
      {/* Display error message if error state is set */}
      {success && (
        <Success message={success} clearSuccess={() => setSuccess('')} />
      )}{' '}
      {/* Display success message if success state is set */}
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Subscribe</button>
      </form>
    </div>
  );
};

export default SubscribeToBlog;
