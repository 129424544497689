// Success.js
import React, {useEffect} from 'react';

const Success = ({message, clearSuccess}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      clearSuccess();
    }, 10000);
    // window.scrollTo({top: 0, behavior: 'smooth'});
    return () => clearTimeout(timeout);
  }, [message, clearSuccess]);

  return <div className="success">{message}</div>;
};

export default Success;
