import React, {useState, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import {Button} from '@material-ui/core';
// import axios from '../axios';
import {
  Person,
  People,
  Email,
  Phone,
  Send,
  AttachMoney,
  SettingsApplications,
  MessageOutlined,
} from '@material-ui/icons';
import Error from '../common/Error';
import Success from '../common/Success';
import '../css/contact.css';
function Contact({option, serviceTitle}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [service, setService] = useState('');

  //error handling
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [success, setSuccess] = useState('');
  const reRef = useRef();

  let recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  const onFormSubmit = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_YOUR_USER_ID
      )
      .then((res) => {
        console.log(e.target.value);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
        //error handling
        setSuccessMsg(
          "Thank you! Your message has been sent successfully. We'll be in touch shortly."
        );
        setSuccess(true);
        setTimeout(() => {
          setSuccessMsg('');
          setSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        setErrorMsg(err.text);
        setError(true);
        setTimeout(() => {
          setErrorMsg(
            'Oops! Something went wrong. Please try again later or contact us directly at info@technoefx.com.'
          );
          setError(false);
        }, 5000);
      });
  };
  return (
    <div className="contact" id="contact">
      {error && <Error message={errorMsg} clearError={() => setError('')} />}{' '}
      {/* Display error message if error state is set */}
      {success && (
        <Success message={successMsg} clearSuccess={() => setSuccess('')} />
      )}{' '}
      <div className={`contact__container ${!option && 'main__contact'}`}>
        <form onSubmit={onFormSubmit}>
          <div className="input__divs">
            <Person className="icons" />
            <input
              className="input1"
              name="firstName"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              required
              placeholder="First name"
            />
          </div>
          <div className="input__divs">
            <People className="icons" />
            <input
              className="input1"
              name="lastName"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
              placeholder="Last name"
            />
          </div>
          <div className="input__divs">
            <Email className="icons" />
            <input
              className="input3"
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              placeholder="Email address"
            />
          </div>
          <div className="input__divs">
            <Phone className="icons" />
            <input
              className="input4"
              name="phone"
              type="number"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              required
              placeholder="Phone"
            />
          </div>
          <div className={`input__divs `}>
            <SettingsApplications className="icons" />
            <select
              name="service"
              onChange={(e) => setService(e.target.value)}
              required
              value={service}
            >
              <option value="">How Can We Assist You?</option>
              <option value="Technology Strategy Consulting">
                Technology Strategy Consulting
              </option>
              <option value="Process Automation (RPA)">
                Process Automation (RPA)
              </option>
              <option value="API Development & Integration">
                API Development & Integration
              </option>
              <option value="UI/UX Design & Product Development">
                UI/UX Design & Product Development
              </option>
              <option value="Cloud Solutions & DevOps">
                Cloud Solutions & DevOps
              </option>
              <option value="Ongoing Maintenance & Support">
                Ongoing Maintenance & Support
              </option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="input__divs">
            <MessageOutlined className="icons" />
            <textarea
              className="input4"
              name="text"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              placeholder="Your message here..."
              rows="4"
            />
          </div>
          <div className="input__divs">
            <Send className="btn__icon" />
            <Button type="submit">Submit</Button>
          </div>

          {/* <div className="verify">
            <ReCAPTCHA sitekey={recaptchaKey} size="invisible" ref={reRef} />
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default Contact;
