import React from 'react';
import '../css/ContactUsPage.css';
import Contact from './Contact';

const ContactUsPage = () => {
  return (
    <div className="contact__us__container">
      <div className="contact__banner">
        <h2 className="title">Get in Touch with TechnoEFX</h2>
        <p>
          We’d love to hear from you! Whether you’re interested in our services
          or need expert guidance, feel free to reach out. Our team is ready to
          assist you with custom software solutions, strategy consulting, and
          more.
        </p>
      </div>
      <div className="content-wrapper">
        <div className="address__section">
          <h2 className="title">Our Office</h2>
          <p>TechnoEFX Ltd</p>
          <p>960 Capability Green</p>
          <p>Luton</p>
          <p>Bedfordshire, LU1 3PE</p>
          <h2 className="title">Phone</h2>
          <a href="tel:+447475566762">
            <p className="phone">+44 (0) 7475 566 762</p>
          </a>
          <h2 className="title">Email</h2>
          <a href="mailto:info@technoefx.com" target="_blank" rel="noreferrer">
            <p className="email">info@technoefx.com</p>
          </a>
        </div>
        <div className="contact__form">
          <h2 className="title">Contact Us</h2>
          <Contact />
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
