export const fadeInAnimationVariant = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index,
      duration: 1,
      type: 'tween',
      stiffness: 120,
    },
  }),
};
export const ourStoryFadeInAnimationVariant = {
  initial: {
    opacity: 0,
    x: '100vw',
  },
  animate: (index) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.05 * index,
      duration: 1,
      type: 'tween',
      stiffness: 120,
    },
  }),
};
