import React from 'react';
import img1 from '../images/img1.webp';
import img2 from '../images/img2.png';
import testingImg from '../images/testing-process-page.png';
import agileImg from '../images/agile-prcocess-page.png';
import launchImg from '../images/launch-process-page.png';
import ProcessJson from './ProcessJson.json';
import '../css/process.css';
import {motion} from 'framer-motion'; // Add framer-motion for animations

function Process() {
  // Import images dynamically and assign them to the JSON data
  const importedImages = [img2, img1, agileImg, testingImg, launchImg];

  // Assign images dynamically to the process data
  const imageLoopFunc = () => {
    for (let i = 0; i < ProcessJson.length; i++) {
      ProcessJson[i].image = importedImages[i];
    }
  };

  imageLoopFunc();

  // Motion variants for animations
  const fadeIn = {
    initial: {opacity: 0, y: 50},
    animate: {opacity: 1, y: 0},
  };

  return (
    <div className="process">
      <div className="title">
        <h2>The Process</h2>
        <p>
          At TechnoEFX, we focus on delivering innovative, tailored software
          solutions designed to empower your business. By aligning technology
          with your goals, we create seamless, efficient systems that drive
          sustainable success. Our collaborative approach ensures clear
          communication and a smooth process, helping your business thrive from
          the initial consultation through to ongoing support.
        </p>
      </div>
      <div className="bg__div"></div>
      <div className="otherservices__container">
        {ProcessJson.map((data, index) => (
          <motion.div
            className="content"
            key={data.title}
            variants={fadeIn}
            initial="initial"
            animate="animate"
            transition={{delay: index * 0.5, duration: 1}} // Animate each block with a slight delay
          >
            <div className="content__body">
              <img
                src={data.image}
                alt={data.title}
                className="process-image"
              />
              <h2>{data.title}</h2>
              <p>{data.para}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Process;
